<template>
  <div id="email-info" v-cloak v-loading.fullscreen.lock="loading">
    <el-row class="email-row-2">
      <el-col :span="24">
        <div class="email-heard-1">
          <el-select
            size="mini"
            v-model="select.flag"
            :placeholder="$t('emailList.marked')"
            @change="changeFlagMove($event, 'flag')"
          >
            <el-option value="is_flag">
              {{ $t("emailList.star") }}
            </el-option>
            <el-option value="no_flag">
              {{ $t("emailList.unStar") }}
            </el-option>
          </el-select>
          <el-select
            v-if="
              emailInfo.cus_folder != '!cus_sendBox' && emailInfo.is_delete == 0
            "
            size="mini"
            v-model="select.move"
            :placeholder="$t('emailList.moveTo')"
            @change="changeFlagMove($event, 'move')"
          >
            <el-option
              value="!cus_inBox"
              :label="$t('emailList.inbox')"
            ></el-option>
            <el-option
              value="!cus_rubbishBox"
              :label="$t('emailList.trash')"
            ></el-option>
            <el-option
              v-for="(folder, index) in folderList"
              :value="folder.id + ''"
              :label="folder.name"
              :key="index"
            >
            </el-option>
          </el-select>
        </div>
      </el-col>
    </el-row>
    <el-row class="email-row-2">
      <el-col :span="24">
        <div class="email-heard-1">
          <el-button type="primary" size="mini" @click="returnEmail('zhuanFa')">
            {{ $t("emailList.forward") }}
          </el-button>
          <el-button type="info" size="mini" @click="returnEmail('huiFu')">
            {{ $t("emailList.reply") }}
          </el-button>
          <el-button
            type="warning"
            size="mini"
            v-if="emailInfo.is_delete == 0"
            @click="changeFlagMove('!cus_rubbishBox', 'move')"
          >
            {{ $t("emailList.delete") }}
          </el-button>
          <el-button
            type="success"
            size="mini"
            v-else
            @click="changeFlagMove('!cus_return', 'move')"
          >
            {{ $t("emailList.restore") }}
          </el-button>
          <el-button
            type="danger"
            size="mini"
            @click="deleteTrue('!cus_rubbishBox', 'delete')"
          >
            {{ $t("emailList.delete1") }}
          </el-button>
        </div>
      </el-col>
    </el-row>
    <el-row class="email-row-0">
      <el-col :span="2">
        <div class="email-tit" v-if="emailInfo.is_sent">
          {{ $t("emailList.to") }}:
        </div>
        <div class="email-tit" v-else>{{ $t("emailList.from") }}:</div>
      </el-col>
      <el-col :span="20">
        {{ emailInfo.to_email }}
      </el-col>
    </el-row>
    <el-row class="email-row-0">
      <el-col :span="2">
        <div class="email-tit">{{ $t("emailList.subject") }}:</div>
      </el-col>
      <el-col :span="20" :class="[emailInfo.is_flag ? 'el-icon-star-on' : '']">
        {{ emailInfo.subject }}
      </el-col>
    </el-row>
    <el-row class="email-row-0" v-if="emailInfo.is_Attachments">
      <el-col :span="2">
        <div class="email-tit">{{ $t("taskInfo.attachment") }}:</div>
      </el-col>
      <el-col :span="20">
        <p v-for="(item, index) in emailInfo.attachments" :key="index">
          <span>{{ item.name }}</span>
          <span
            v-if="!emailInfo.isSentNow"
            :class="[
              item.isCache ? 'isCacheIcon' : 'noCacheIcon',
              'download-icon',
            ]"
            @click="downloadAtt(emailInfo, item)"
          >
            <i class="icon-xiazai iconfont"></i>
            {{ $t("download") }}
          </span>
        </p>
      </el-col>
    </el-row>
    <el-row class="email-row-1">
      <el-col :span="24">
        <div class="subject-border" v-html="emailInfo.content"></div>
      </el-col>
    </el-row>
    <div v-if="emailInfo.view_records.length > 0" class="logo-out">
      <h1 class="logTit">
        {{ $t("emailInfo.history") }}
        :
      </h1>
      <el-table :data="emailInfo.view_records" class="logTable skuSelectTable">
        <el-table-column prop="ip" label="IP" width="200"> </el-table-column>
        <el-table-column
          prop="create_time"
          :label="$t('emailList.time')"
          width="200"
        >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { Post } from "@/utils/common";
import { toMessage } from "@/utils/message";
export default {
  name: "EmailInfo",
  data() {
    return {
      emailInfoId: 0,
      loading: false,
      emailInfo: {},
      isDownload: false,
      admin: {
        id: 0,
      },
      //select下拉
      select: {
        move: "",
        flag: "",
      },
      folderList: [],
    };
  },
  methods: {
    //打开邮件，邮件详情
    getEmailInfo() {
      return new Promise((resolve) => {
        this.axios
          .post("/home/CrmEmail/getEmailInfo", {
            id: this.emailInfoId,
          })
          .then((res) => res.data)
          .then((res) => {
            this.loading = false;
            if (res.status != 1) {
              this.$message(toMessage("error", res.msg));
              return;
            }
            resolve(res.data);
            if (res.data.is_sent == 1) {
              res.data.to_email = Object.keys(
                JSON.parse(res.data.to_email)
              ).join(" , ");
            }
            this.emailInfo = res.data;
            this.select.move = res.data.cus_folder;
          })
          .catch(() => {
            this.loading = false;
            this.$message(toMessage("error", this.$t("error")));
            return;
          });
      });
    },
    /**
     * 彻底删除
     */
    deleteTrue(value, bind) {
      this.$confirm(this.$t("emailInfo.text"), this.$t("tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(() => {
          this.changeFlagMove(value, bind);
        })
        .catch(() => {});
    },
    /**
     * 触发下拉移动文件夹等事件
     */
    changeFlagMove(value, bind) {
      this.select[bind] = "";
      this.loading = true;
      this.axios
        .post("/home/CrmEmail/dealBind", {
          selectedId: [this.emailInfo.id],
          bind: bind,
          value: value,
        })
        .then((res) => res.data)
        .then((res) => {
          switch (res.status) {
            case 1:
              this.$message(toMessage());
              break;
            case 2:
              this.$message(
                toMessage("warning", res.data + this.$t("emailInfo.warning"))
              );
              break;
            case 3:
              this.$message(
                toMessage("warning", res.data + this.$t("emailInfo.warning1"))
              );
              break;
            default:
              this.$message(toMessage("error", res.msg));
              return;
          }
          this.loading = false;
          if (bind == "delete") {
            this.addTab("CrmEmail", "inbox", "");
          }
          this.getEmailInfo();
        });
    },
    addTab: function (
      option = "Index",
      action = "welCome",
      name = "首页",
      param
    ) {
      let url = "/" + option + "/" + action;
      if (param) {
        for (var i in param) {
          url += "/" + param[i];
        }
      }
      console.log(name);
      this.$router.push(url);
    },
    returnEmail(returnType) {
      this.addTab("CrmEmail", "writeEmail", "<tags:lang>写信</tags:lang>", {
        returnType: returnType,
        emailInfoId: this.emailInfo.id,
      });
    },
    /**
     * 获取用户自定义文件夹
     */
    getFolderList() {
      return new Promise((resolve) => {
        this.axios
          .post("/home/CrmEmail/getFolderList")
          .then((res) => res.data)
          .then((res) => {
            this.loading = false;
            if (res.status != 1) {
              this.$message(toMessage("error", res.msg));
              return;
            }
            this.folderList = res.data;
            resolve(this.folderList);
          });
      });
    },
    getAdminId() {
      return new Promise((resolve) => {
        this.axios
          .post("/home/User/getInfo")
          .then((res) => res.data)
          .then((res) => {
            this.loading = false;
            if (res.status != 1) {
              this.$message(toMessage("error", res.msg));
              return;
            }
            this.admin.id = Number(res.data.user_id);
            resolve(this.admin.id);
          });
      });
    },
    //下载邮件的附件
    downloadAtt(emailInfo, attachment) {
      if (this.isDownload) {
        this.$message(toMessage("success", this.$t("emailInfo.text1")));
        return;
      }
      if (!attachment.isCache) {
        this.$message(toMessage("success", this.$t("emailInfo.text2")));
      }
      this.isDownload = true;
      //验证身份信息且查询邮件是否存在(开始预下载)
      this.axios
        .post("/home/Plugs/downloadAtt", {
          email_id: emailInfo.id,
          attachmentPath: attachment.path,
          attachmentId: attachment.id,
        })
        .then((res) => res.data)
        .then((res) => {
          this.isDownload = false;
          if (res.status == 0) {
            this.$message(toMessage("error", res.msg));
            return;
          }
          let path = attachment.path;
          if (Object.keys(res.data).length > 0) {
            this.$set(this.emailInfo, "attachments", res.data.attachments);
            path = res.data.path;
          } else {
            //修改原附件显示为已预下载完成
            for (let i in this.emailInfo.attachments) {
              this.emailInfo.attachments[i].isCache = 1;
            }
          }
          let now_have = res.status == 2 ? 1 : 0;
          //开始真正下载
          //根据邮箱地址选择服务器地址
          //已经下载到服务，询问用户是否下载到本地
          this.$confirm(this.$t("emailInfo.text3"), this.$t("tips"), {
            confirmButtonText: this.$t("confirm"),
            cancelButtonText: this.$t("cancel"),
            type: "warning",
          })
            .then(() => {
              // TODO
              Post(
                now_have
                  ? "/home/plugs/downloadAttNow"
                  : this.emailInfo.attrDomain + "/Index/downloadAttNow",
                [
                  { name: "path", value: path },
                  { name: "name", value: attachment.name },
                  { name: "user_id", value: this.admin.id },
                  { name: "email_id", value: emailInfo.id },
                ]
              );
            })
            .catch(() => {});
        });
    },
  },
  created: function () {
    this.emailInfoId = this.$route.params.emailInfoId;
    this.getFolderList();
    this.getAdminId();
    this.getEmailInfo().then((resolve) => {
      if (resolve.is_seen == 0 && resolve.is_sent == 0) {
        this.changeFlagMove("is_seen", "flag");
      }
    });
  },
};
</script>

<style scoped>
#email-info {
  padding: 28px !important;
  height: 100%;
  overflow: auto;
}
.email-row-0,
.email-row-1 {
  margin: 20px;
}

.email-row-0 {
  font-size: 14px;
  margin: 20px;
  color: #6e6e6f;
}

.email-tit {
  text-align: right;
  padding-right: 10px;
}

.icon-big {
  font-size: 20px;
}

.el-icon-star-on {
  color: #ee3d3d;
}

.subject-border {
  border-top: 1px dashed #8f9192;
  padding-top: 20px;
}

.email-row-2 {
  margin-bottom: 40px;
}

.email-heard-1 {
  position: absolute;
  right: 50px;
}

.isCacheIcon {
  color: #15c051;
}

.noCacheIcon {
  color: #666666;
}

.download-icon {
  cursor: pointer;
}
</style>